// Sub Component

// Section 1: React low level imports
import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Section 2: Material-UI imports
import {
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Input,
    Grid,
    MenuItem,
    Select,
    SvgIcon,
    Tooltip,
    Typography,
    Radio,
    InputAdornment,
    CircularProgress,
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { XCircle as XIcon } from "react-feather";
import { HelpCircle as HelpIcon } from "react-feather";

// Section 3: Components & Containers import from the application
import ToolTipWrapper from "./ToolTipWrapper";

import { getTokenIcon } from "utils/ui";

// Section 4: Define Styles function taking theme as argument and returning an object
const DrawerLiquidateClass = makeStyles(
    (theme) =>
        createStyles({
            drawer: {
                width: "100%",
                "@media (min-width: 780px)": {
                    width: 330,
                },
                "@media (min-width: 1940px)": {
                    width: 380,
                },
            },
            btnDrawer: {
                minWidth: 100,
                textTransform: "capitalize",
                fontWeight: 500,
                minHeight: 32,
            },
            inputWrap: {
                position: "relative",
            },
            maxTips: {
                position: "absolute",
                right: "15px",
                top: "17px",
                cursor: "pointer",
                color: "#ffffff",
                fontSize: "14px",
            },
            input: {
                background: "#08090c 0% 0% no-repeat padding-box",
                border: "1px solid #000000",
                borderRadius: "4px",
                opacity: 1,
                height: "50px",
                paddingLeft: "10px",
                color: "white",
                fontSize: "16px",
                "& .MuiInputBase-input": {
                    padding: "6px 0 6px",
                },
            },
            select: {
                background: "#1B1B1B 0% 0% no-repeat padding-box",
                border: "1px solid #000000",
                borderRadius: "4px",
                opacity: 1,
                height: "50px",
                color: "white",
                fontSize: "16px",
                "& .MuiSelect-selectMenu": {
                    padding: "6px 10px",
                },
                "& .MuiSelect-select:focus": {
                    background: "none",
                },
                "& .MuiSelect-icon": {
                    right: "10px",
                },
            },
            rateInfo: {
                color: "white",
                fontSize: "14px",
                marginTop: "20px",
            },
            rateRow: {
                padding: "12px 0",
                borderBottom: "1px dashed #a7a7a7",
            },
            cardTitle: {
                fontSize: "16px",
                marginTop: "0px",
                marginBottom: "3px",
                fontWeight: 500,
            },
            cardSubtitle: {
                fontSize: "12px",
                color: "#979797",
                margin: "0px",
                fontWeight: "400",
            },
            wrapperRadio: {
                padding: "10px",
                border: "1px solid #000000",
                borderRadius: "4px",
                marginBottom: "12px",
                "&:hover": {
                    border: "1px solid rgba(240, 240, 240, 0.3)",
                    cursor: "pointer",
                },
            },
            textRight: {
                textAlign: "right",
            },
            borderWhite: {
                border: "1px solid #FFF",
            },
            mr15: {
                marginRight: "15px",
            },
            styleRadio: {
                padding: "0px",
            },
            text: {
                fontSize: "12px",
                color: "#979797",
                marginBottom: "5px",
                textTransform: "none",
            },
            bonusBox: {
                paddingTop: 3,
            },
            assetImg: {
                marginRight: "12px",
                width: "25px",
                height: "25px",
                paddingTop: "3px",
            },
            inputIcon: {
                width: "25px",
                height: "25px",
                marginRight: "4px",
            },
            vertCent: {
                margin: "auto",
            },
            amount: {
                paddingTop: "20px",
            },
            helpIcon: {
                width: ".8rem",
                marginLeft: "8px",
                color: "#979797",
            },
        }),
    {
        name: "drawerLiquidate",
        index: 1,
    }
);

const DrawerLiquidateWrapper = ({
    loanList,
    collateralAssetList,

    buttonsLabel,

    shortfallETH,
    onButtonClick,

    onSetMax,
    onResetAmount,
    amount,
    onSetAmount,

    enableButtonClick,
    isDoingAction,

    selectedLoanAsset,
    setSelectedLoanAsset,
    selectedCollateralAsset,
    setSelectedCollateralAsset,

    marketsData,

    walletIsKYCd,

    maxAmountForToken,
    maxAmountToDisplay,

    walletConnected,
    onConnect,
}) => {
    const classes = DrawerLiquidateClass();

    // UI state
    const [isOpen, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedLoanAssetData, setSelectedLoanAssetData] = React.useState(
        {}
    );
    const [
        selectedCollateralAssetData,
        setSelectedCollateralAssetData,
    ] = React.useState({});

    const handleChooseSupplyCoin = useCallback(
        (val) => {
            if (!val) return;

            setSelectedLoanAsset(val.symbol);
            setSelectedLoanAssetData(val);
        },
        [setSelectedLoanAsset, setSelectedLoanAssetData]
    );

    const handleChooseBorrowCoin = useCallback(
        (val) => {
            if (!val) return;

            setSelectedCollateralAsset(val);

            const assetFromcollateralAssetList = collateralAssetList.find(
                (e) => e.symbol === val
            );

            setSelectedCollateralAssetData(assetFromcollateralAssetList);
        },
        [
            setSelectedCollateralAsset,
            setSelectedCollateralAssetData,
            collateralAssetList,
        ]
    );
    const handleChangeDropdownBorrow = (event) => {
        handleChooseBorrowCoin(event.target.value);
    };

    // Choose first entry by default on load/data change
    useEffect(() => {
        if (loanList.length > 0) {
            handleChooseSupplyCoin(loanList[0]);
        }
    }, [loanList, handleChooseSupplyCoin]);

    useEffect(() => {
        if (collateralAssetList.length > 0)
            handleChooseBorrowCoin(collateralAssetList[0].symbol);
    }, [collateralAssetList, handleChooseBorrowCoin]);

    const displayAssetsRadio = ({
        list,
        selectedAsset,
        handleChooseCoin,
        showDiscount,
    }) => {
        return (
            <>
                {list.map((val) => (
                    <Grid
                        key={val.symbol}
                        container
                        justify="space-between"
                        alignItems="center"
                        className={
                            selectedAsset === val.symbol
                                ? clsx(
                                      classes.wrapperRadio,
                                      classes.borderWhite
                                  )
                                : classes.wrapperRadio
                        }
                        onClick={() => handleChooseCoin(val)}
                    >
                        <Grid container item xs>
                            <img
                                src={getTokenIcon(val.symbol)}
                                alt="asset"
                                width="35px"
                                className={classes.mr15}
                            />
                            <Grid>
                                <h3 className={classes.cardTitle}>
                                    {maxAmountForToken(val.symbol).toFixed(6)}{" "}
                                    {
                                        val.symbol === "WETH"
                                            ? "ETH"
                                            : val.symbol /* Re-labeling WETH to ETH only */
                                    }
                                </h3>
                                <h5 className={classes.cardSubtitle}>
                                    $
                                    {
                                        maxAmountForToken("USDC").toFixed(
                                            2
                                        ) /* using USDC calculation now for USD value */
                                    }
                                </h5>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.textRight}>
                            <Radio
                                checked={selectedAsset === val.symbol}
                                value={val.symbol}
                                name="radio-button"
                                className={classes.styleRadio}
                                size="small"
                            />
                            {showDiscount && (
                                <Box className={classes.bonusBox}>
                                    <span>10% </span>
                                    <span
                                        style={{
                                            color: "#a7a7a7",
                                            textTransform: "none",
                                        }}
                                    >
                                        Bonus
                                    </span>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </>
        );
    };

    const displayAssetsDropdown = ({ list }) => {
        return list.map((val) => (
            <MenuItem key={val.symbol} value={val.symbol}>
                <Box display="flex" alignItems="center">
                    <Box>
                        <img
                            src={getTokenIcon(val.symbol)}
                            alt="asset"
                            className={classes.assetImg}
                        />{" "}
                    </Box>
                    <Box>
                        {" "}
                        {
                            val.symbol === "WETH"
                                ? "ETH"
                                : val.symbol /* Re-labeling WETH to ETH only */
                        }
                    </Box>
                </Box>
            </MenuItem>
        ));
    };

    const getPercentage = (theValue, appliedPercentage) => {
        return theValue * appliedPercentage;
    };

    const displayReceiveAmountOfTokensForInput = (pay, receive) => {
        // get Pay Asset Oracle Price
        const assetMarketDataForPay = marketsData.find((e) => e.symbol === pay);

        // get Receive Asset Oracle Price
        const assetMarketDataForReceive = marketsData.find(
            (e) => e.symbol === receive
        );

        let calculatedValue = 0;

        if (
            assetMarketDataForPay !== undefined &&
            assetMarketDataForPay &&
            assetMarketDataForReceive !== undefined &&
            assetMarketDataForReceive
        ) {
            let payAmount = amount;

            // if User Amount is Max
            if (amount === -1) {
                payAmount = maxAmountForToken(selectedLoanAsset);
            }

            // gets ETH amount for in put tokens
            // inputAmount * loan token Oracle Price = Input ETH amount
            calculatedValue = payAmount * assetMarketDataForPay.oraclePrice;

            // ReceiveTokenAmount = Input ETH Amount / collateral token Oracle Price
            calculatedValue =
                calculatedValue / assetMarketDataForReceive.oraclePrice;

            return calculatedValue.toFixed(6);
        }
    };

    // Section 5: Code Component
    return (
        <>
            <ToolTipWrapper
                walletIsKYCd={walletIsKYCd}
                toolTipTitle="You are not KYC authorized"
            >
                <Button
                    type="button"
                    className={classes.btnDrawer}
                    variant="contained"
                    size="small"
                    onClick={handleOpen}
                    disabled={walletIsKYCd !== true}
                >
                    {isDoingAction ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        "View"
                    )}
                </Button>
            </ToolTipWrapper>
            <Drawer
                anchor="right"
                classes={{ paper: classes.drawer }}
                ModalProps={{ BackdropProps: { invisible: false } }}
                onClose={handleClose}
                open={isOpen}
                variant="temporary"
            >
                <Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                    >
                        <Typography variant="h4" color="textPrimary">
                            Liquidate
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <SvgIcon fontSize="small">
                                <XIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                    <Divider />

                    {/*  <Box pt={5} p={2}>
                        <Grid container className={classes.amount}>
                            Outstanding Loans
                        </Grid>
                        {displayAssetsRadio({
                            list: collateralAssetList,
                            selectedAsset: selectedCollateralAsset,
                            handleChooseCoin: handleChooseBorrowCoin,
                            showDiscount: false,
                        })}
                    </Box>*/}

                    <Box pt={5} px={2}>
                        <Box pb={1}>
                            <Typography variant="body1" color="textPrimary">
                                Outstanding Loan
                            </Typography>
                        </Box>
                        {displayAssetsRadio({
                            list: loanList,
                            selectedAsset: selectedLoanAsset,
                            handleChooseCoin: handleChooseSupplyCoin,
                            showDiscount: false,
                        })}
                    </Box>

                    <Box px={2} className={classes.amount}>
                        <Box display="flex" alignItems="center" pb={1}>
                            <Typography variant="body1" color="textPrimary">
                                Amount
                            </Typography>
                            <Tooltip
                                title="What you pay"
                                placement="right"
                                arrow
                                className={classes.toolTip}
                            >
                                <HelpIcon
                                    fontSize="small"
                                    className={classes.helpIcon}
                                />
                            </Tooltip>
                        </Box>
                        <Box className={classes.inputWrap}>
                            <Input
                                autoComplete="off"
                                autoFocus={true}
                                disableUnderline={true}
                                className={classes.input}
                                placeholder="0.00"
                                type={amount < 0 ? "text" : "number"}
                                onChange={(e) => onSetAmount(e.target.value)}
                                value={
                                    amount < 0
                                        ? maxAmountToDisplay(
                                              amount,
                                              selectedLoanAsset
                                          )
                                        : amount
                                }
                                fullWidth={true}
                                disabled={amount < 0}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img
                                            src={getTokenIcon(
                                                selectedLoanAsset
                                            )}
                                            alt="asset"
                                            className={classes.inputIcon}
                                        />
                                    </InputAdornment>
                                }
                            />

                            <span
                                className={classes.maxTips}
                                onClick={amount < 0 ? onResetAmount : onSetMax}
                            >
                                {amount < 0 ? "CHANGE" : "MAX"}
                            </span>
                        </Box>
                        <Box className={classes.inputWrap} pt={2}>
                            <Box display="flex" alignItems="center" pb={1}>
                                <Typography variant="body1" color="textPrimary">
                                    Collateral
                                </Typography>
                                <Tooltip
                                    title="What you receive"
                                    placement="right"
                                    arrow
                                    className={classes.toolTip}
                                >
                                    <HelpIcon
                                        fontSize="small"
                                        className={classes.helpIcon}
                                    />
                                </Tooltip>
                            </Box>
                            <Select
                                className={classes.select}
                                labelId="receive-asset"
                                id="receive-asset"
                                value={selectedCollateralAsset}
                                onChange={handleChangeDropdownBorrow}
                                label="Asset"
                                fullWidth={true}
                                autoFocus={true}
                                disableUnderline={true}
                            >
                                {displayAssetsDropdown({
                                    list: collateralAssetList,
                                })}
                            </Select>
                        </Box>
                        <Grid className={classes.rateInfo}>
                            <Grid container className={classes.rateRow}>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    className={classes.vertCent}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        You Pay
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    container
                                    justify="flex-end"
                                >
                                    {maxAmountToDisplay(
                                        amount,
                                        selectedLoanAsset
                                    )}{" "}
                                    {
                                        selectedLoanAsset === "WETH"
                                            ? "ETH"
                                            : selectedLoanAsset /* Re-labeling WETH to ETH only */
                                    }
                                </Grid>
                            </Grid>

                            <Grid container className={classes.rateRow}>
                                <Grid item md={6} className={classes.vertCent}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        You Receive
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    container
                                    justify="flex-end"
                                >
                                    {displayReceiveAmountOfTokensForInput(
                                        selectedLoanAsset,
                                        selectedCollateralAsset
                                    )}{" "}
                                    {
                                        selectedCollateralAsset === "WETH"
                                            ? "ETH"
                                            : selectedCollateralAsset /* Re-labeling WETH to ETH only */
                                    }
                                </Grid>
                            </Grid>

                            <Grid container className={classes.rateRow}>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    className={classes.vertCent}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Bonus
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    container
                                    justify="flex-end"
                                >
                                    {getPercentage(
                                        displayReceiveAmountOfTokensForInput(
                                            selectedLoanAsset,
                                            selectedCollateralAsset
                                        ),
                                        0.1
                                    ).toFixed(6)}{" "}
                                    {
                                        selectedCollateralAsset === "WETH"
                                            ? "ETH"
                                            : selectedCollateralAsset /* Re-labeling WETH to ETH only */
                                    }
                                </Grid>
                            </Grid>

                            <Grid container className={classes.rateRow}>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    className={classes.vertCent}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Receive Total
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs
                                    container
                                    justify="flex-end"
                                >
                                    {(
                                        1.1 *
                                        displayReceiveAmountOfTokensForInput(
                                            selectedLoanAsset,
                                            selectedCollateralAsset
                                        )
                                    ).toFixed(6)}{" "}
                                    {
                                        selectedCollateralAsset === "WETH"
                                            ? "ETH"
                                            : selectedCollateralAsset /* Re-labeling WETH to ETH only */
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box className={classes.sendButtonDiv} mt={4}>
                            <Button
                                type="button"
                                className={classes.btnModal}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (walletConnected) {
                                        onButtonClick({
                                            assetCollateral:
                                                selectedCollateralAssetData.asset,
                                            assetBorrow:
                                                selectedLoanAssetData.asset,

                                            symbol: selectedLoanAsset,
                                        });
                                    } else {
                                        onConnect();
                                    }

                                    handleClose(); // will hide the drawer if user is disconnected and that might be a little bit annoying, solution if it really is to make the connect modal display above the liquidate drawer
                                }}
                                size="large"
                                fullWidth={true}
                                disabled={!enableButtonClick || isDoingAction}
                            >
                                {isDoingAction ? (
                                    <CircularProgress
                                        size={20}
                                        color="inherit"
                                    />
                                ) : (
                                    buttonsLabel
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

// Section 6: Documentation with PropTypes
DrawerLiquidateWrapper.propTypes = {
    buttonsLabel: PropTypes.string.isRequired,
    loanList: PropTypes.array.isRequired,
};

// Section 7:  Connect styles and export
export default DrawerLiquidateWrapper;
