import { createSvgIcon } from "@material-ui/core/utils";

export const History = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="monitoring"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 0.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M11.5577304,2.12060719 C16.3407391,2.8687299 20,7.00703186 20,12 C20,17.5228475 15.5228475,22 10,22 C4.4771525,22 0,17.5228475 0,12 C0,9.11910048 1.22625112,6.43360271 3.33034445,4.54900044 C3.74173622,4.1805235 4.37394473,4.21531285 4.74242166,4.62670462 C5.1108986,5.0380964 5.07610925,5.6703049 4.66471748,6.03878184 C2.97985986,7.54788148 2,9.69378082 2,12 C2,16.418278 5.581722,20 10,20 C14.418278,20 18,16.418278 18,12 C18,8.16847601 15.3064261,4.96606992 11.7095714,4.18307484 L11.8944272,4.5527864 C12.1414164,5.0467649 11.9411921,5.64743794 11.4472136,5.89442719 C10.9532351,6.14141644 10.3525621,5.94119209 10.1055728,5.4472136 L9.1145696,3.46520717 C9.10808984,3.45289965 9.10185919,3.44044058 9.09588433,3.42783664 L8.88196601,3 L9.09588413,2.57216375 C9.10185919,2.55955942 9.10808984,2.54710035 9.11456959,2.53479284 L10.1055728,0.552786405 C10.3525621,0.0588079071 10.9532351,-0.14141644 11.4472136,0.105572809 C11.9411921,0.352562058 12.1414164,0.953235098 11.8944272,1.4472136 L11.5577304,2.12060719 Z M11,9 L11,11.5857864 L12.7071068,13.2928932 C13.0976311,13.6834175 13.0976311,14.3165825 12.7071068,14.7071068 C12.3165825,15.0976311 11.6834175,15.0976311 11.2928932,14.7071068 L9,12.4142136 L9,9 C9,8.44771525 9.44771525,8 10,8 C10.5522847,8 11,8.44771525 11,9 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "History"
);
