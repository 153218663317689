import { createSvgIcon } from "@material-ui/core/utils";

export const Liquidate = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="bucket"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 2.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M15.8630542,5.0208186 C14.2880203,5.6930137 12.0050917,6 9,6 C5.99490515,6 3.71197493,5.69301306 2.13694086,5.0208165 L2.85693148,15.8206758 C2.91789202,16.735084 3.59253342,17.4910627 4.49414316,17.6552671 C5.7511923,17.8842187 7.25413989,18 9,18 C9.55228475,18 10,18.4477153 10,19 C10,19.5522847 9.55228475,20 9,20 C7.14127434,20 5.52085981,19.8751695 4.13578056,19.6228993 C2.33257009,19.2944921 0.983282169,17.7825289 0.86136116,15.9537138 L0.0172914756,3.29266822 C-0.0143210381,2.81988683 0.122994176,2.35125274 0.404720711,1.97026895 C1.44644124,0.561531801 4.24227571,0 9,0 C13.7574066,0 16.5531714,0.561463824 17.5950406,1.96994622 C17.8768602,2.35091103 18.0142486,2.81956768 17.982727,3.29239157 L17.5199095,10.2346533 C19.1677661,12.1573181 20,13.7150041 20,15 C20,17.6167327 17.6998051,20 15,20 C12.2385763,20 10,17.7614237 10,15 C10,13.1664803 11.4587454,10.9783622 14.3121227,8.27417301 L15.0187269,7.60451339 L15.6488055,8.23459195 L15.8630542,5.0208186 L15.8630542,5.0208186 Z M15.65886,2.88298525 C14.769686,2.35441567 12.5063514,2 9,2 C5.49362972,2 3.23029996,2.35442464 2.34116349,2.88300037 C3.43685615,3.58764036 5.66627749,4 9,4 C12.3337464,4 14.5631758,3.58763446 15.65886,2.88298525 L15.65886,2.88298525 Z M18,15 C18,14.1205927 17.003505,12.5402321 14.9826053,10.4139789 C12.9786174,12.4311744 12,13.999098 12,15 C12,16.6568542 13.3431458,18 15,18 C16.5790469,18 18,16.5277287 18,15 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "Liquidate"
);
