import { createSvgIcon } from "@material-ui/core/utils";

export const External = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="card_and_coin"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 3.500000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M2,7.5 L2,12.5 C2,13.6045695 2.8954305,14.5 4,14.5 L10,14.5 C10,14.1357129 10.0973943,13.7941734 10.2675644,13.5 C10.0973943,13.2058266 10,12.8642871 10,12.5 L10,10.5 C10,9.3954305 10.8954305,8.5 12,8.5 L17,8.5 L17,7.5 L2,7.5 Z M2,5.5 L17,5.5 L17,4.5 C17,3.3954305 16.1045695,2.5 15,2.5 L4,2.5 C2.8954305,2.5 2,3.3954305 2,4.5 L2,5.5 Z M10,16.5 L4,16.5 C1.790861,16.5 7.10542736e-15,14.709139 7.10542736e-15,12.5 L7.10542736e-15,4.5 C7.10542736e-15,2.290861 1.790861,0.5 4,0.5 L15,0.5 C17.209139,0.5 19,2.290861 19,4.5 L19,8.76756439 C19.5978014,9.11337381 20,9.75971764 20,10.5 L20,12.5 C20,12.8642871 19.9026057,13.2058266 19.7324356,13.5 C19.9026057,13.7941734 20,14.1357129 20,14.5 L20,16.5 C20,17.6045695 19.1045695,18.5 18,18.5 L12,18.5 C10.8954305,18.5 10,17.6045695 10,16.5 Z M12,14.5 L12,16.5 L18,16.5 L18,14.5 L12,14.5 Z M18,12.5 L18,10.5 L12,10.5 L12,12.5 L18,12.5 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "External"
);
