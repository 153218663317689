import { createSvgIcon } from "@material-ui/core/utils";

export const Coins = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="coins2"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 2.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M3,8 C2.44771525,8 2,8.44771525 2,9 L2,11 C2,11.5522847 2.44771525,12 3,12 L4.00820114,12 L5.01640228,12 C5.11483585,10.504513 5.65160962,9.1287554 6.49945072,8 L3,8 Z M12,5.01640228 L12,3 C12,2.44771525 11.5522847,2 11,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,5 C2,5.55228475 2.44771525,6 3,6 L5,6 L8.75582565,6 C9.72190454,5.44231088 10.8239292,5.09381174 12,5.01640228 Z M14,5.15002717 C17.4233605,5.84493267 20,8.87156313 20,12.5 C20,16.6421356 16.6421356,20 12.5,20 C12.3770547,20 12.2548003,19.9970417 12.1333041,19.9911925 C12.0896951,19.9970015 12.0451977,20 12,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,15 C0,14.2316456 0.288853568,13.5307565 0.763893493,13 C0.288853568,12.4692435 0,11.7683544 0,11 L0,9 C0,8.23164556 0.288853568,7.53075649 0.763893493,7 C0.288853568,6.46924351 0,5.76835444 0,5 L0,3 C0,1.34314575 1.34314575,0 3,0 L11,0 C12.6568542,0 14,1.34314575 14,3 L14,5.15002717 Z M3,14 C2.44771525,14 2,14.4477153 2,15 L2,17 C2,17.5522847 2.44771525,18 3,18 L7.40093151,18 C6.27327488,16.9540556 5.4678211,15.5655699 5.15002717,14 L3,14 Z M12.5,18 C15.5375661,18 18,15.5375661 18,12.5 C18,9.46243388 15.5375661,7 12.5,7 C9.46243388,7 7,9.46243388 7,12.5 C7,15.5375661 9.46243388,18 12.5,18 Z"
                    id="Combined-Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "Coins"
);
