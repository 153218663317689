import { createSvgIcon } from "@material-ui/core/utils";

export const Lend = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="chart_line_up"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 1.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M16,3.61803399 L15.4472136,3.89442719 C14.9532351,4.14141644 14.3525621,3.94119209 14.1055728,3.4472136 C13.8585836,2.9532351 14.0588079,2.35256206 14.5527864,2.10557281 L16.5347928,1.1145696 C16.5471004,1.10808984 16.5595594,1.10185919 16.5721634,1.09588433 L17,0.881966011 L17.4278362,1.09588413 C17.4404406,1.10185919 17.4528996,1.10808984 17.4652072,1.11456959 L19.4472136,2.10557281 C19.9411921,2.35256206 20.1414164,2.9532351 19.8944272,3.4472136 C19.6474379,3.94119209 19.0467649,4.14141644 18.5527864,3.89442719 L18,3.61803399 L18,4 C18,11.1797017 12.1797017,17 5,17 C4.44771525,17 4,16.5522847 4,16 C4,15.4477153 4.44771525,15 5,15 C11.0751322,15 16,10.0751322 16,4 L16,3.61803399 Z M0,2 C0,1.44771525 0.44771525,1 1,1 C1.55228475,1 2,1.44771525 2,2 L2,17 C2,18.1045695 2.8954305,19 4,19 L19,19 C19.5522847,19 20,19.4477153 20,20 C20,20.5522847 19.5522847,21 19,21 L4,21 C1.790861,21 0,19.209139 0,17 L0,2 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "Lend"
);
