// Section 1: React/Redux low level imports
import React from "react";

// Section 2: internal imports
import Swap from "components/Swap/Swap";

// Section 3: Selectors

// Section 4: Container body
const SwapContainer = () => {
    return <Swap />;
};

// Section 5: Exports
export default SwapContainer;
