import { createSvgIcon } from "@material-ui/core/utils";

export const Markets = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="chart"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 2.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M4,0 L16,0 C18.209139,0 20,1.790861 20,4 L20,16 C20,18.209139 18.209139,20 16,20 L4,20 C1.790861,20 0,18.209139 0,16 L0,4 C0,1.790861 1.790861,0 4,0 Z M4,2 C2.8954305,2 2,2.8954305 2,4 L2,16 C2,17.1045695 2.8954305,18 4,18 L16,18 C17.1045695,18 18,17.1045695 18,16 L18,4 C18,2.8954305 17.1045695,2 16,2 L4,2 Z M7,14 C7,14.5522847 6.55228475,15 6,15 C5.44771525,15 5,14.5522847 5,14 L5,12 C5,11.4477153 5.44771525,11 6,11 C6.55228475,11 7,11.4477153 7,12 L7,14 Z M11,14 C11,14.5522847 10.5522847,15 10,15 C9.44771525,15 9,14.5522847 9,14 L9,9 C9,8.44771525 9.44771525,8 10,8 C10.5522847,8 11,8.44771525 11,9 L11,14 Z M15,14 C15,14.5522847 14.5522847,15 14,15 C13.4477153,15 13,14.5522847 13,14 L13,6 C13,5.44771525 13.4477153,5 14,5 C14.5522847,5 15,5.44771525 15,6 L15,14 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "Markets"
);
