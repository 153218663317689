import { createSvgIcon } from "@material-ui/core/utils";

export const Dashboard = createSvgIcon(
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="home"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <g
                id="full"
                transform="translate(2.000000, 1.500000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <path
                    d="M1.7815846,4.05831153 L9.55324769,0.60423905 C9.83766958,0.477829321 10.1623304,0.477829321 10.4467523,0.60423905 L18.2184154,4.05831153 C19.3017994,4.53981555 20,5.61418003 20,6.79974618 L20,16.5 C20,18.709139 18.209139,20.5 16,20.5 L4,20.5 C1.790861,20.5 0,18.709139 0,16.5 L0,6.79974618 C0,5.61418003 0.698200563,4.53981555 1.7815846,4.05831153 Z M10,2.59431753 L2.59386153,5.88593463 C2.23273352,6.04643597 2,6.40455746 2,6.79974618 L2,16.5 C2,17.6045695 2.8954305,18.5 4,18.5 L16,18.5 C17.1045695,18.5 18,17.6045695 18,16.5 L18,6.79974618 C18,6.40455746 17.7672665,6.04643597 17.4061385,5.88593463 L10,2.59431753 Z M9,10.5 L11,10.5 C11.5522847,10.5 12,10.9477153 12,11.5 L12,13.5 C12,14.0522847 11.5522847,14.5 11,14.5 L9,14.5 C8.44771525,14.5 8,14.0522847 8,13.5 L8,11.5 C8,10.9477153 8.44771525,10.5 9,10.5 Z"
                    id="Shape"
                ></path>
            </g>
        </g>
    </svg>,
    "Dashboard"
);
