const typography = {
    body1: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.6,
        "@media (min-width:1940px)": {
            fontSize: "1rem",
        },
    },

    body2: {
        fontWeight: 400,
        fontSize: "	0.75rem",
        "@media (min-width:1940px)": {
            fontSize: "0.8125rem",
        },
    },

    h1: {
        fontWeight: 400,
        fontSize: "2.25rem",
        "@media (min-width:1940px)": {
            fontSize: "2.625rem",
        },
    },
    h2: {
        fontWeight: 400,
        fontSize: "1.5rem",
        "@media (min-width:1940px)": {
            fontSize: "1.75rem",
        },
    },
    h3: {
        fontWeight: 400,
        fontSize: "1.375rem",
        "@media (min-width:1940px)": {
            fontSize: "1.5rem",
        },
    },
    h4: {
        fontWeight: 400,
        fontSize: "1rem",
        "@media (min-width:1940px)": {
            fontSize: "1.1875rem",
        },
    },
    h5: {
        fontWeight: 500,
        fontSize: "	0.9375rem",
        "@media (min-width:1940px)": {
            fontSize: "1rem",
        },
    },
    h6: {
        fontWeight: 400,
        fontSize: "	0.9375rem",
    },

    subtitle1: {
        fontWeight: 400,
        lineHeight: 1.7,
        fontSize: "0.8125rem",
    },

    subtitle2: {
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: 1.3,
    },

    overline: {
        fontWeight: 400,
        fontSize: 50,
        lineHeight: 1.5,
        textTransform: "capitalize",
    },
};

export default typography;
