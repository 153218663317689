// Section 1: React/Redux low level imports
import React from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";

import web3 from "web3";
import * as yup from "yup";

import Big from "big.js";

// Section 2: internal imports
import {
    formatNumber,
    formatETHMoney,
    toWei,
    fromWei,
    assetToDecimals,
    formatStringNumber,
    formatETHMoneyOrgFee,
} from "utils/ui";

import useWalletSelect from "hooks/useWalletSelect";

import constants from "constants/constant.json";

// Section 3: Selectors, Hooks & Action Listeners
import { getUSDPrice } from "redux/selectors/data/totalBalance";
import { onTx } from "redux/actions/status/transactions";

import { walletAddress, walletNetwork } from "redux/selectors/data/onboard";

import { isWalletKYCd, getTransactions } from "redux/selectors/status/status";

import { getPool, isKYCPool } from "redux/selectors/status/pool";

// Section 4: Import Underlying Components / Containers
import Drawer from "components/UI/Drawer/Drawer";

// Section 5: Code Container
const BorrowDrawerContainer = ({ asset, onTx }) => {
    const { library } = useWalletSelect();

    const userWalletAddress = useSelector(walletAddress);
    const userWalletNetwork = useSelector(walletNetwork);

    const walletIsKYCd = useSelector(isWalletKYCd);
    const txs = useSelector(getTransactions);

    const USDPrice = useSelector(getUSDPrice); // retrieves the USD price from the store

    const needKYC = useSelector(isKYCPool);
    const orginationFee = constants.orginationFee;

    const [amount, setAmount] = React.useState("");

    const currentPool = useSelector(getPool);

    const onSetAmount = (newValue) => {
        const numberOfDecimalsForAsset = assetToDecimals[asset.unit]
            ? assetToDecimals[asset.unit].toString()
            : "18"; // chose 18 as default, arbitrary choice here
        const regex = new RegExp(
            "^(?:\\d*\\.\\d{0," + numberOfDecimalsForAsset + "}|\\d+)$"
        );

        if (newValue === "" || (newValue + "").match(regex))
            // limiting inputting more decimal places than the asset supports
            setAmount(newValue);
    };

    const getMaxBorrow = () => {
        if (
            web3.utils
                .toBN(toWei(asset.maxBorrowToken, asset.unit))
                .lte(web3.utils.toBN(asset.liquidity))
        ) {
            return asset.maxBorrowToken;
        } else {
            return fromWei(asset.liquidity, asset.unit);
        }
    };
    const onSetMax = () => {
        setAmount(getMaxBorrow());
    };

    const [enableBorrow, setEnableBorrow] = React.useState(false);

    const [isProcessing, setIsProcessing] = React.useState(false);

    const [formattedOrginationFee, setFormattedOrginationFee] =
        React.useState("0.00");

    const [calculatedBorrowFee, setCalculatedBorrowFee] = React.useState(0);

    React.useEffect(() => {
        if (amount && orginationFee) {
            const amountBig = Big(amount);

            const calculatedBorrowFeeBig = amountBig.times(Big(orginationFee));

            if (calculatedBorrowFeeBig.gte(Big(0.000001)))
                setCalculatedBorrowFee(
                    parseFloat(calculatedBorrowFeeBig.toFixed(6))
                );
            else if (calculatedBorrowFeeBig.eq(Big(0)))
                setCalculatedBorrowFee(0);
            else setCalculatedBorrowFee("<0.000001");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, orginationFee]);

    React.useEffect(() => {
        if (asset.price && amount && asset.unit && USDPrice) {
            setFormattedOrginationFee(
                formatETHMoneyOrgFee(
                    asset.price,
                    amount,
                    "USD",
                    asset.unit,
                    USDPrice
                )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset.price, amount, asset.unit, USDPrice]);

    React.useEffect(() => {
        const tx = Object.values(txs).filter((aTx) => {
            return (
                aTx.assetUnit === asset.unit &&
                aTx.type === "borrow" &&
                aTx.processing === true
            );
        });

        if (tx && tx.length > 0) setIsProcessing(tx[0].processing);
        else setIsProcessing(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [txs]);

    React.useEffect(() => {
        const checkValidity = async () => {
            let schema = yup
                .number()
                .required()
                .positive()
                .test("maxValueTest", "You can't borrow this amount", () => {
                    return (
                        web3.utils.toBN(toWei(amount, asset.unit)).lte(
                            web3.utils.toBN(
                                toWei(asset.maxBorrowToken, asset.unit) // checks amount against maxBorrow amount
                            )
                        ) &&
                        web3.utils
                            .toBN(toWei(amount, asset.unit))
                            .lte(web3.utils.toBN(asset.liquidity)) // checks amount against protocol liquidity
                    );
                });
            setEnableBorrow(await schema.isValid(amount));
        };

        checkValidity();
    }, [
        amount,
        asset.balance,
        asset.maxBorrowToken,
        asset.unit,
        asset.wallet,
        asset.liquidity,
    ]);

    return (
        <Drawer
            asset={asset}
            buttonsLabel="Borrow"
            triggerButtonVariant="contained"
            enableButtonClick={enableBorrow}
            showWarningColor={true}
            onButtonClick={() =>
                onTx({
                    userWalletNetwork,
                    userWalletAddress,
                    asset,
                    amount,
                    type: "borrow",
                    shouldSendMax: false, // shouldSendMax==true will send max_UINT, for this call we should not send it and set a precise amount,
                    currentPool,
                    library,
                })
            }
            isDoingAction={isProcessing}
            usdPrice={formatETHMoney(asset.price, "USD", asset.unit, USDPrice)}
            onSetMax={onSetMax}
            amount={amount}
            setAmount={onSetAmount}
            mainTitle={`Borrow ${
                asset.alternativeUnitLabel
                    ? asset.alternativeUnitLabel
                    : asset.unit
            }`}
            balanceValue={formatNumber(
                asset.balance,
                asset.unit,
                userWalletNetwork
            )}
            amountValue={`Available: ${formatStringNumber(
                getMaxBorrow(),
                null,
                userWalletNetwork
            )}`}
            walletIsKYCd={needKYC ? walletIsKYCd : true}
            displayOriginationFee={true}
            orginationFee={orginationFee}
            formattedOrginationFee={formattedOrginationFee}
            calculatedBorrowFee={calculatedBorrowFee}
            currentBalanceLabel="Current borrow balance"
        />
    );
};
// Section 6: Dispatch & Props Mapping
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({ onTx }, dispatch);

// Section 7: Export Container
export default compose(connect(mapStateToProps, mapDispatchToProps))(
    BorrowDrawerContainer
);
